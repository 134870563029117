<template>
  <div class="card mt-5 shadow bg-light">

    <div class="text-center">
      <img src="../assets/images/bemag-full-grey-500.png" class="card-img-top img-fluid" style="max-width: 600px" alt="Bemag">
    </div>

    <div @keyup.enter="login" class="card-body">

      <div class="text-center">
        <h3 class="card-title">Iniciar sesión</h3>
      </div>

      <div class="form-group">
        <label for="usuario">Usuario:</label>
        <input type="text" class="form-control" v-model="usuario">
      </div>

      <div class="form-group">
        <label for="passwd">Contraseña:</label>
        <input type="password" class="form-control" v-model="passwd">
      </div>

      <div class="text-center">
        <LoadingButton @clicked="login" :iconProp="'fas fa-sign-in-alt'" :loadingProp="loading" :classProp="'btn btn-success'" :textProp="'Entrar'" class="m-1"></LoadingButton>
      </div>

      <div class="mt-4 text-center">
          <router-link to="/registro" class="card-link">Registro</router-link>
          <router-link to="/recuperarpasswd" class="card-link">Recuperar contraseña</router-link>
      </div>

    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  import Swal from 'sweetalert2'
  import config from '../config'
  import LoadingButton from '../components/LoadingButton.vue'

  export default {
    data() {
      return {
        loading: false,
        usuario: null,
        passwd: null
      }
    },
    components: {
      LoadingButton
    },
    methods: {
      login: async function () {
        try {
          if (!this.usuario) {
            return Swal.fire({
              title: 'Error',
              text: 'El usuario no puede estar vacío',
              icon: 'error',
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true
            })
          }

          this.loading = true

          await axios.post(config.apiUrl + '/usuarios/login', {
            usuario: this.usuario,
            passwd: this.passwd
          })

          this.$router.push('/')
        } catch (e) {
          this.loading = false

          Swal.fire({
            title: 'Error',
            text: e.response.data,
            icon: 'error',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true
          })
        }
      }
    },
    mounted() {
      this.$store.commit('setPublicLayout')
    }
  }
</script>
